import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { TextField, Box, Grid } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FormControlLabel, Checkbox } from '@mui/material';

export default function VehicleCard(props) {  
  const linkStyle = {
    color: "white",
    textDecoration: "none",
  }


  const [openInputs, setOpenInputs] = useState(false);
  const [sellerName, setSellerName] = useState('');
  const [sellerContact, setSellerContact] = useState('');
  const [condition, setCondition] = useState('');
  const [doors, setDoors] = useState('');
  const [intColor, setIntColor] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, { [name]: true }]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => !option[name])
      );
    }
  };

  const vehicle = props?.data

  const handleButtonClick = () => {
    setOpenInputs(!openInputs);
  };

  const handleInputChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission or further processing of the inputs

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to submit.',
      buttons: [
          {
          label: 'Yes',
          onClick: async () => {
            const values = {
              Vehicle_Id: vehicle._id,
              Vehicle_Manufacturer: vehicle.Vehicle_Manufacturer,
              Model: vehicle.Model,
              Year: vehicle.Manufacturing_Year,
              Engine: `${vehicle.Vehicle_Information?.Number_Of_Cylinders?.Value} Cyclinders`,
              Product_Description: description,
              Price: price,
              Added_Date: new Date(),
              Features: selectedOptions,
              Location: "",
              VIN: vehicle.Registration_Number,
              Availability: "Available",
              Cylinders: `${vehicle.Vehicle_Information?.Number_Of_Cylinders?.Value} Cyclinders`,
              Condition: condition,
              Exterior_Color: `${vehicle.Color}`,
              Kilometers: `${vehicle.Vehicle_Information?.Odometer}`,
              Body_Style: `${vehicle.Vehicle_Information?.Body_Type?.Value}`,
              Transmission: vehicle.Vehicle_Information?.Transmission_Type?.Value,
              Fuel_Type: `${vehicle.Vehicle_Information?.Engine_Type?.Value}`,
              Interior_Color: intColor,
              Doors: doors,
              Specs: `${vehicle.Vehicle_Information?.Regional_Specs?.Value} ${vehicle.Vehicle_Information?.Options?.Value}`,
              Seller_Name: sellerName,
              Seller_Contact: sellerContact,
              Username: localStorage.getItem('user'),
              Images: vehicle.Images,
            }
        
            const response = await fetch(`${process.env.REACT_APP_API}/add/classifieds`, {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                values
                            })
                        })
          }
          },
          {
          label: 'No',
          onClick: () => alert('Re submit')
          }
      ]
      });

    
    setOpenInputs(false)
  };


  return props?.data ?
   (
    <Card sx={{ width: "17em" }}>
      <CardMedia
        component="img"
        height="200px"
        width="100%"
        image={`${process.env.REACT_APP_API}/images/${props?.data?.Images[0]}`}
        alt="Car Image"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props?.data?.Vehicle_Manufacturer}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props?.data?.Model}  
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props?.data?.Manufacturing_Year}  
        </Typography>
      </CardContent>
      <CardActions> 
        <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                <Link style={linkStyle} underline="none" to={`/vehicle/edit/${props?.data?._id}`}><Typography textAlign="center">Edit</Typography></Link>
        </Button>
        <Button onClick={handleButtonClick} sx={{ my: 2, display: 'block' }}>
            <Typography style={{color: 'red'}} textAlign="center">Push to Classified</Typography>
        </Button>
        </CardActions>
        <CardActions>
        {openInputs && (
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Seller Name"
            value={sellerName}
            onChange={(e) => handleInputChange(e, setSellerName)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Seller Contact"
            value={sellerContact}
            onChange={(e) => handleInputChange(e, setSellerContact)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Vehicle Description"
            value={description}
            onChange={(e) => handleInputChange(e, setDescription)}
            multiline
            rows={7}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Number of Doors"
            value={doors}
            onChange={(e) => handleInputChange(e, setDoors)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Interior Color"
            value={intColor}
            onChange={(e) => handleInputChange(e, setIntColor)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Condition"
            value={condition}
            onChange={(e) => handleInputChange(e, setCondition)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            value={price}
            onChange={(e) => handleInputChange(e, setPrice)}
            fullWidth
            margin="normal"
          />

          <Grid>
            <Typography>Features</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="ABS"
                  checked={selectedOptions.some((option) => option.ABS)}
                  onChange={handleCheckboxChange}
                />
              }
              label="ABS"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="Air_Bags"
                  checked={selectedOptions.some((option) => option.Air_Bags)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Air Bags"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="Alloy_Rims"
                  checked={selectedOptions.some((option) => option.Alloy_Rims)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Alloy Rims"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="Brake_Assit"
                  checked={selectedOptions.some((option) => option.Brake_Assit)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Brake Assit"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="Cruise_Control"
                  checked={selectedOptions.some((option) => option.Cruise_Control)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Cruise Control"
            />

          <FormControlLabel
              control={
                <Checkbox
                  name="Immobilizer_Key"
                  checked={selectedOptions.some((option) => option.Immobilizer_Key)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Immobilizer Key"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="Power_Steering"
                  checked={selectedOptions.some((option) => option.Power_Steering)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Power Steering"
            />
          </Grid>



          <Button sx={{marginTop: 5}} type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      )}
      </CardActions>
    </Card>
  ) :
  (<></>)
}
