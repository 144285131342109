import React, { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export const EvaluationBar = ({data}) => {
    const [showTable, setShowTable] = useState(0);
    const [details, setDetails] = useState({})

    const getDetails = async (username) => {
        console.log(username)
        fetch(`${process.env.REACT_APP_API}/contact/${username}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data?.email && data?.number) {
                setDetails({
                    email: data?.email,
                    number: data?.number
                })
            } else {
                setDetails({
                    email: "Not found",
                    number: "Not found"
                })
            }
            
          })
      }  

    useEffect(()=>{
        getDetails(data?.Username)
    },[])

    {/* <img key={index} src={`${process.env.REACT_APP_API}/images/${image}`} alt={`Image ${index + 1}`} /> */}
    return (
        <>  
            <Box component={Paper} sx={{ width: '100%', padding: '20px', marginTop: '50px' }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Item>
                    <Typography variant="subtitle1">
                        Manufacturer and Model:
                    </Typography>
                    <Typography variant="body1">
                        {data?.Vehicle_Manufacturer + " " + data?.Model}
                    </Typography>
                    </Item>
                    <Item>
                    <Typography variant="subtitle1">
                        Year:
                    </Typography>
                    <Typography variant="body1">
                        {data?.Year}
                    </Typography>
                    </Item>
                    <Item>
                    <Typography variant="subtitle1">
                        Price:
                    </Typography>
                    <Typography variant="body1">
                        {data?.Price}
                    </Typography>
                    </Item>
                    <Item>
                    <Typography variant="subtitle1">
                        Added Date:
                    </Typography>
                    <Typography variant="body1">
                        {data?.Added_Date ? new Date(data?.Added_Date).toISOString().split('T')[0] : ""}
                    </Typography>
                    </Item>
                    <Button component={Paper} onClick={() => setShowTable(!showTable)}>
                    See More
                    </Button>
                </Stack>
                </Box>

            {
                !showTable ? <></> : <><TableContainer component={Paper}>
                <Table sx={{ /* minWidth: 650 */ }} aria-label="simple table">
                <TableBody>
                {data && Object.keys(data).map((x, index)=>{
                   return index === 0 || x === "__v" ||  x === "Images" || x === "Username" || x === "Added_Date" ?  <></>:
                   (
                    <TableRow key={index+x}>
                        <TableCell>{x.replace("_", " ")}</TableCell>
                        <TableCell>{Array.isArray(data[x]) ? "" : data[x]}</TableCell>
                    </TableRow>)
                })}


                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{details?.email}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>{details?.number}</TableCell>
                </TableRow>
              
                <TableRow>
                    <TableCell>
                        <Button component={Paper} onClick={() => setShowTable(!showTable)}>Close</Button>
                    </TableCell>
                </TableRow>

                </TableBody>
                </Table>
            </TableContainer>
            <Box>
            <Carousel>
                        {data?.Images?.map((image, idx) => (
                        <div key={idx}>
                            <img src={`${process.env.REACT_APP_API}/images/${image}`} alt={`Image ${idx}`} />
                        </div>
                        ))}
                    </Carousel>
                    <Button component={Paper} onClick={() => setShowTable(!showTable)}>Close</Button>
            </Box>
            </>
            }
            
        </>
    )
}