import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CustomSlipModal({username}) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [user,setUser] =React.useState({})
    const fetchUser = () => {
      fetch(`${process.env.REACT_APP_API}/contact/${username}`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setUser(data)
        })
    }

    React.useEffect(() => {
      fetchUser();
  }, [])

  return (
    <div>
      <Button onClick={handleOpen}>Generate</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Number: {`${user ? user.number: ""}`}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
  Email: {`${user ? user.email: ""}`}
          </Typography>
          
        </Box>
      </Modal>
    </div>
  );
}