import { Grid, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EvaluationBar } from '../components/EvaluationBar';
import { paperStyle } from '../styles';

export default function Evaluations() {
    const [evaluations, setEvaluations] = useState([]);

    const fetchevaluations = () => {
        fetch(`${process.env.REACT_APP_API}/evaluations`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            const evaluations = data.data
            const filteredEvaluations = evaluations.filter(
              evaluation => (`${evaluation.Vehicle_Manufacturer} ${evaluation?.Model}`).toLowerCase().includes(searchText.toLowerCase())
            );
            setEvaluations(filteredEvaluations)
          })
    }

    const [searchText, setSearchText] = useState('');

    useEffect(()=>{
        fetchevaluations();
    },[searchText])


  

  // Filter evaluations based on the search text
  

  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };

  return (
    <Grid style={paperStyle}>
      Evaluations
      <Grid sx={{marginTop: 5}}>
            <TextField
              label="Search by Manufacturer"
              value={searchText}
              onChange={handleSearchChange}
            />
      </Grid>
        {evaluations?.length > 0 && (
            evaluations?.map((evaluation, index) => {
                return <EvaluationBar key={index} data={evaluation} />
            })
        )}
    </Grid>
  )
}