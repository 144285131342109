import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Select } from '@mui/material/Select';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export const AccountBar = ({data}) => {
    const [status, setStatus] = useState(data?.status)
    const [auction, setAuction] = useState(data?.auction)

    const activate = async (x) => {
        const response = await fetch(`${process.env.REACT_APP_API}/accounts/activate`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: x
            })
        })
        const data = await response.json()
        if (data) {
           setStatus('Active')
        }
    }

    const activateAuction = async (x) => {
        const response = await fetch(`${process.env.REACT_APP_API}/accounts/auction/activate`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: x
            })
        })
        const data = await response.json()
        if (data) {
           setAuction('active')
        }
    }

    const deactivateAuction = async (x) => {
        const response = await fetch(`${process.env.REACT_APP_API}/accounts/auction/deactivate`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: x
            })
        })
        const data = await response.json()
        if (data) {
           setAuction(null)
        }
    }

    const deactivate = async (x) => {
        const response = await fetch(`${process.env.REACT_APP_API}/accounts/deactivate`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: x
            })
        })
        const data = await response.json()
        if (data) {
           setStatus('Inactive')
        }
    }

    return (
        <Box component={Paper} sx={{ width: '100%', padding: '20px', marginTop: '50px' }}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                style={{display: 'flex',
                justifyContent: 'space-between',}}
            >
            <Item>
                {data?.email}
            </Item>
            <Item>
                {data?.username}
            </Item>
            <Item>
                {data?.number}
            </Item>
            <Item>
                {data?.status}
            </Item>
            <Item>
                {auction ? 
                auction === 'inactive' ? 
                <Button style={{backgroundColor: 'maroon'}} onClick={()=>{activateAuction(data?.username)}}>
                    Inactive for Auction
                </Button> :
                auction === 'pending' ?
                <Button style={{backgroundColor: 'orange', color: 'black'}} onClick={()=>{activateAuction(data?.username)}}>
                    Pending for Auctions
                </Button>
                :
                <Button style={{backgroundColor: 'green'}} onClick={()=>{deactivateAuction(data?.username)}}>
                    Activated for Auctions
                </Button>
                :
                <Button style={{backgroundColor: 'maroon'}} onClick={()=>{activateAuction(data?.username)}}>
                    Inactive for Auction
                </Button>
                }
            </Item>
        {/*     <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
                >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
            </Select> */}

                {
                    status === 'Inactive' ? 
                    <Item>
                        <Button style={{backgroundColor: 'green'}} onClick={()=>{activate(data?._id)}}>
                            Authorize Account
                        </Button>
                    </Item>
                    :
                    <Item>
                        <Button style={{backgroundColor: 'maroon'}} onClick={()=>{deactivate(data?._id)}}>
                            Block Account
                        </Button>
                    </Item>
                }
            </Stack>
        </Box>
    )
}