import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

export default function ClassifiedCard(props) {  
  const linkStyle = {
    color: "white",
    textDecoration: "none",
  }


  const [openInputs, setOpenInputs] = useState(false);
  const [sellerName, setSellerName] = useState('');
  const [sellerContact, setSellerContact] = useState('');
  const [condition, setCondition] = useState('');
  const [price, setPrice] = useState('');
  const classified = props?.data

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await fetch(`${process.env.REACT_APP_API}/classifieds/${props?.data?._id}`, {
              method: 'DELETE',
              headers: {'Content-Type': 'application/json'}
            })
        
            console.log(await response.json())
            window.location.reload(true)
          }
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });    
  }

  return(
    <>
        <Card sx={{ width: "17em" }}>
          {
            props?.data?.Images ? props?.data?.Images.length > 0 ? <CardMedia
            component="img"
            height="200px"
            width="100%"
            image={`${process.env.REACT_APP_API}/images/${props?.data?.Images[0]}`}
            alt="Car Image"
          /> : <></> : <></>
          }
        
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {`${props?.data?.Vehicle_Manufacturer} ${props?.data?.Model}  ${props?.data?.Year}  `}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Name: {props?.data?.Seller_Name}  
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Number: {props?.data?.Seller_Contact}  
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Added Date: {new Date(props?.data?.Added_Date).toISOString().split('T')[0]}  
          </Typography>
        </CardContent>
        <CardActions> 
    
            <Button onClick={handleButtonClick} sx={{ my: 2, display: 'block' }}>
            <Typography textAlign="center">
              View
            </Typography>
          </Button>
          <Button onClick={handleSubmit} sx={{ my: 2, display: 'block' }}>
                  <Typography style={{color: 'red'}} textAlign="center">Delete</Typography>
            </Button>

        </CardActions>
      </Card>
      <Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
       
            margin: 'auto',
            backgroundColor: '#000000',
            padding: '20px',
            maxWidth: 800,
            marginTop: 5,
            borderRadius: '8px',
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          <TableContainer>
          <Button onClick={handleClose} sx={{ my: 2, display: 'block' }}>
            <Typography textAlign="center">
              Close
            </Typography>
          </Button>
            <Table>
              <TableBody>
                {classified &&
                  Object.keys(classified).map((x, index) => {
                    if (
                      index === 0 ||
                      x === 'Vehicle_Id' ||
                      x === '__v' ||
                      x === 'Username'
                    ) {
                      return null;
                    }

                    if (x === 'Images') {
                      return (
                        <TableRow key={index + x}>
                          <TableCell>Images</TableCell>
                          <TableCell>
                            <Carousel>
                              {classified[x] && classified[x].map((image, idx) => (
                                <div key={idx}>
                                  <img src={`${process.env.REACT_APP_API}/images/${image}`} alt={`Image ${idx}`} />
                                </div>
                              ))}
                            </Carousel>
                          </TableCell>
                        </TableRow>
                      );
                    }

                    if (x === 'Product_Description') {
                      return (
                        <TableRow key={index + x}>
                          <TableCell>Product Description</TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ p: 2 }}>
                              {Array.isArray(classified[x]) ? '' : classified[x]}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }

                    if (x === 'Features') {
                      return (
                        <React.Fragment key={index + x}>
                          <TableRow>
                            <TableCell>Features</TableCell>
                          </TableRow>
                          {classified[x].length > 0 &&
                            classified[x]
                              .filter((obj) => Object.values(obj)[0] === true)
                              .map((obj) => (
                                <TableRow key={Object.keys(obj)[0]}>
                                  <TableCell style={{ paddingRight: 0 }}>
                                    - {String(Object.keys(obj)[0])}
                                  </TableCell>
                                </TableRow>
                              ))}
                        </React.Fragment>
                      );
                    }

                    return (
                      <TableRow key={index + x}>
                        <TableCell>{x.replace('_', ' ')}</TableCell>
                        <TableCell>
                          {Array.isArray(classified[x]) ? '' : classified[x]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Box>
</>
)

}
